export const environment = {
  production: false,
  service: {
    host: 'https://app-sit-v2.tungngern-gateway.arisetech.dev',
  },
  version: '1.32.0-dev',
  externalUrl: {
    register: 'https://tungngern-dev.web-registration.th-service.co.in/',
  },
  security: {
    publicKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjo2fj5clnu4DNNQmSwx/
XUymIi02dywlXqA6SV2qOAiJXvWki3Teh8rPCpRS3dFS6D1xxjIyGufMIAT+y3Mp
CUI8o46v8Qs6WIwFuszv6/Q4MPBPH3EY2peQPR0GyX1atGD2pg9DE7L47ZDzvETR
tjpeGx+/qPWpYmku8qo6jgUg3um8vy9M2fTRKv65eikeDVpICZqNxH7hy01TRO9k
AlCBntXhfOLB98UbwW1tyrFh5Tt+mLz9mmgbc/KK+walKMGUiL+nvSAXjovAV1ZT
/iSBxscZ9G3juX0qxZut3lxzsAVVCs///HU6zv3r4DV+7YS0+V8oTANRoixD/t99
3QIDAQAB
-----END PUBLIC KEY-----`,
  },
  webUrl: '',
  google: {
    ga: 'G-462R7Q0677',
    gtag: 'GTM-KHC8DGC',
  },
  videos: {
    url1: 'https://storage.googleapis.com/tungngern-common-bucket-public-sit/web-register/videos/VDO_%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%97%E0%B8%B5%E0%B9%886_%E0%B8%84%E0%B9%89%E0%B8%99%E0%B8%AB%E0%B8%B2%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2.mp4',
    url2: 'https://storage.googleapis.com/tungngern-common-bucket-public-sit/web-register/videos/VDO_%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%97%E0%B8%B5%E0%B9%885_%E0%B8%9D%E0%B8%B1%E0%B9%88%E0%B8%87%E0%B8%A5%E0%B8%B9%E0%B8%81%E0%B8%84%E0%B9%89%E0%B8%B2.mp4',
  },
  storage: {
    kiosk: '/assets/json/kiosk.json',
  },
};
